<template>
  <div>
    <Divider dashed><span class="divider-text">{{leftPageType==='create'?'创建':'编辑'}}成本项</span></Divider>

    <Form ref="serviceItemData" id="serviceItemData" :model="serviceItemData" :rules="ruleValidate" label-position="top">
        <FormItem label="配置项名称" prop="name">
          <Input size="small" v-model="serviceItemData.name" placeholder="请填写配置项名称" />
        </FormItem>

        <!-- <Row :gutter="8">
          <i-col span="12">
            <FormItem label="售卖价格" prop="price">
              <InputNumber
                :max="1000000"
                style="width:100%"
                size="small"
                v-model="serviceItemData.price"
                :formatter="value => `￥ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')"
                :parser="value => value.replace(/\￥\s?|(,*)/g, '')"></InputNumber>
            </FormItem>
          </i-col>
          <i-col span="12">
            <FormItem label="单位" prop="unit">
              <RadioGroup v-model="serviceItemData.unit">
                  <Radio v-for="item in unitList" :key="item.value" :label="item.value">{{item.name}}</Radio>
              </RadioGroup>
            </FormItem>
          </i-col>
        </Row> -->

        <FormItem label="设备类型" prop="devicemodel">
          <Select v-model="serviceItemData.devicemodel" size="small" placeholder="请选择设备类型">
            <Option :value="-1">全设备</Option>
            <Option v-for="item in deviceModelList" :key="item.id" :value="item.id">{{item.name}}</Option>
          </Select>
        </FormItem>

        <FormItem label="是否默认采购" prop="defaultSelect">
          <Checkbox v-model="serviceItemData.defaultSelect">默认采购</Checkbox>
        </FormItem>

        <FormItem label="任务类型" prop="costType">
          <Select v-model="serviceItemData.costType" size="small" placeholder="请选择运维任务类型">
            <Option v-for="item in taskItemTypes" :key="item.value" :value="item.value">{{item.name}}</Option>
          </Select>
        </FormItem>

        <FormItem v-if="serviceItemData.costType === 3" label="工艺材质" prop="materialId">
          <Select v-model="serviceItemData.materialId" size="small" placeholder="请选择制作工艺材质">
            <Option v-for="item in materialList" :key="item.id" :value="item.id">{{item.name}}</Option>
          </Select>
        </FormItem>

        <FormItem label="服务规则" prop="serviceItemDetailDtoList">
          <Card :bordered="false" class="m-t-5 m-b-10" v-for="(item,index) in serviceRules" :key="index">
            <p slot="title">{{item.companyName}}</p>
            <div slot="extra">
              <a @click="handleEditServiceRule(item,index)">编辑</a>
              |
              <a @click="handleDeleteServiceRule(index)">删除</a>
            </div>
            <Row>
              <i-col span="12"><span class="title">费用：</span>{{item.price}}元</i-col>
              <i-col span="12"><span class="title">单位：</span>{{item.unitName}}</i-col>
            </Row>
            <Row>
              <i-col span="12"><span class="title">开始时间：</span>{{item.startTime}}</i-col>
              <i-col span="12"><span class="title">结束时间：</span>{{item.endTime}}</i-col>
            </Row>
            <Row>
              <i-col span="12"><span class="title">费用类型：</span>{{item.feeTypeName}}</i-col>
              <i-col span="12"><span class="title">质保有效期：</span>{{item.period}}天</i-col>
            </Row>
            <Row>
              <i-col span="12"><span class="title">资产：</span>{{item.assetName}}</i-col>
              <i-col span="12"><span class="title">位置：</span>{{item.positionName}}</i-col>
            </Row>
            <Row>
              <i-col span="12" v-if="serviceItemData.costType===5"><span class="title">分组：</span>{{item.groupName}}</i-col>
            </Row>
          </Card>
        </FormItem>

        <Row>
          <i-col span="12">
            <a @click="handleAddServiceRule"><Icon type="md-add" size="16"/>添加新的服务规则</a>
          </i-col>
          <i-col span="12" class="text-right">
            <Button type="success" size="small" :disabled="serviceRules.length<1" @click="handleSubmitServiceItem">
              {{leftPageType==='create'?'提交':'保存'}}成本项
            </Button>
          </i-col>
        </Row>
    </Form>

    <modifyServiceRule ref="modifyServiceRule" :companyId="companyId" :publisherId="publisherId" @update-sevice-rule="handleUpdateServiceRule"/>
  </div>
</template>

<script>
import em from '@/utils/enum'
import modifyServiceRule from '../common/modifyServiceRule'
import { getMaterialList, addServiceItem, getServiceItem } from '@/api/msp/serviceitem'

export default {
  components: {
    modifyServiceRule
  },
  data () {
    return {
      companyId: this.$store.getters.token.userInfo.companyId,
      publisherId: this.$store.getters.token.userInfo.publisherId,

      serviceItemData: {
        name: '',
        price: 0,
        unit: 1,
        defaultSelect: true,
        materialId: 0,
        devicemodel: 0,
        costType: 0,
        serviceItemDetailDtoList: ''
      },
      ruleValidate: {
        name: [
          { required: true, message: ' ', trigger: 'blur' }
        ]
      },

      materialList: [], // 工艺材质清单
      unitList: em.maintenanceUnitEnum,
      serviceRules: [] // 当前服务规则项
    }
  },
  created () {
    this.initPageData()
  },
  methods: {
    initPageData () {
      this.$store.dispatch('getDeviceModelData').then(res => {
        this.serviceItemData.devicemodel = res[0].id
      })

      getMaterialList().then(res => {
        this.materialList = res
      })

      this.$store.dispatch('getTaskItemTypesData').then(res => {
        this.serviceItemData.costType = res[0].value
      })
    },
    handleAddServiceRule () {
      this.$refs.modifyServiceRule.showModal(true, this.serviceItemData.costType)
    },
    handleEditServiceRule (serviceRuleBean, index) {
      this.$refs.modifyServiceRule.showModal(false, this.serviceItemData.costType, serviceRuleBean, index)
    },
    handleUpdateServiceRule (params) {
      if (params.isAdd) {
        this.serviceRules.push(params.serviceRuleBean)
      } else {
        this.serviceRules[params.editIndex] = params.serviceRuleBean
      }
    },
    handleDeleteServiceRule (index) {
      this.serviceRules.splice(index, 1)
    },
    handleSubmitServiceItem () {
      this.$refs.serviceItemData.validate((valid) => {
        if (valid) {
          this.serviceItemData.serviceItemDetailDtoList = this.serviceRules
          const postData = {
            publisherId: this.publisherId,
            serviceitemJson: JSON.stringify(this.serviceItemData)
          }

          addServiceItem(postData).then(res => {
            this.$Notice.success({ desc: '添加成本项成功！' })
            // 刷新底部表单
            this.$store.commit('set_service_config_beginUpdateBottom', new Date())
            // 清单当前数据
            this.resetPage()
          })
        }
      })
    },
    resetPage () {
      this.serviceItemData = {
        name: '',
        price: 0,
        unit: 1,
        defaultSelect: true,
        materialId: 0,
        devicemodel: this.deviceModelList[0].id,
        costType: this.taskItemTypes[0].value,
        serviceItemDetailDtoList: ''
      }
      this.serviceRules = []
    }
  },
  computed: {
    deviceModelList () { // 设备类型清单
      return this.$store.state.serviceConfig.deviceModelListData
    },
    taskItemTypes () { // 任务类型
      return this.$store.state.serviceConfig.taskItemTypesData
    },
    beginUpdateLeft () {
      return this.$store.state.serviceConfig.beginUpdateLeft
    },
    leftPageType () {
      return this.$store.state.serviceConfig.leftPageType
    },
    editId () {
      return this.$store.state.serviceConfig.editId
    }
  },
  watch: {
    beginUpdateLeft () {
      if (this.leftPageType === 'create') {
        this.resetPage()
      } else if (this.leftPageType === 'edit') {
        // 获取当前配置项的信息
        getServiceItem({ serviceItemId: this.editId }).then(res => {
          this.serviceItemData = res
          this.serviceRules = res.serviceItemDetailDtoList
        })
      }
    }
  }
}
</script>
